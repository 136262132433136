import { StaticQuery } from "gatsby";
import React from "react";
import Layout from "../layouts";
import { BiMap, BiPhone } from "react-icons/bi";
import { getSettingValueByName } from "../shared/dato-cms-helper"

const AboutScreen = () => {
  return (
    <StaticQuery
      query={graphql`
        query AboutQuery {
          about:   datoCmsAbout {
            id
            title
            imageDescription
            contentLeft
            contentRight
            phoneNumber
            openingHours
            address
            image {
              url
            }
          }
          site {
            siteMetadata {
              siteName
            }
          }
          settings: allDatoCmsSetting {
            nodes {
              id
              name
              value
            }
          }
        }
      `}
      render={(data) => {
        const settingPhoneNumber = getSettingValueByName(data, 'Phone Call Number')

        return (
        <Layout site={data.site} phoneNumber={settingPhoneNumber} decorator={
          <div className="about-contact">
            <div className="about-contact__wrapper">
            <div className="contact-inner">
            <h3>Contact Us</h3>
            <div className="inner-infor">
              <ul>
                <li>
                  <BiMap />
                  <span>{data.about.address}</span>
                </li>
                <li>
                  <BiPhone />
                  <a href={`tel:${data.about.phoneNumber}`}>
                    <span>{data.about.phoneNumber}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-inner">
            <h3>Opening Hours</h3>
            <div className="inner-infor">
              <ul>
                <li>
                  <span>{data.about.openingHours}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-inner">
            <h3>Map</h3>
            <div className="inner-infor">
              <iframe title="store-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.5164607625934!2d147.2892272!3d-42.840826899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaa6e74be54f3c21d%3A0x11b8af5426ee0cb1!2s11%20Springfield%20Ave%2C%20Moonah%20TAS%207009%2C%20Australia!5e0!3m2!1sen!2s!4v1663726823531!5m2!1sen!2s"
                allowfullscreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
            </div>
        </div>
        }>
          <div className="about-style">
            <div className="about-wrapper">
              <h2>{data.about.title}</h2>
              <div className="about-welcom">
                <div className="about-welcom__content">
                  <div className="content-item">
                    <p>
                      {data.about.contentLeft}
                    </p>
                  </div>
                  <div className="content-item">
                    <p>
                      {data.about.contentRight}
                    </p>
                  </div>
                </div>
                <div className="about-welcom__img">
                  <figure>
                    <img
                      src={data.about.image.url}
                      alt="bg-cover"
                    />
                  </figure>
                  <span>{data.about.imageDescription}</span>
                </div>
              </div>

            </div>
          </div>
        </Layout>
      )}
    }
    />
  );
};

export default AboutScreen;
